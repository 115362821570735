<template>
    <el-main>
        <el-form class="el-form-search" label-width="90px">
            <el-form-item label="订单编号：">
                <el-input v-model="searchForm.order_no" size="small" placeholder="请输入订单编号"></el-input>
            </el-form-item>
            <el-form-item label="用户昵称：">
                <el-input v-model="searchForm.nickname" size="small" placeholder="请输入用户昵称："></el-input>
            </el-form-item>
            <el-form-item label="创建时间：">
                <el-date-picker v-model="searchForm.start_time" size="small" type="daterange" value-format="timestamp"
                    range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="广告类型：">
                <el-select v-model="searchForm.type" size="small">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option label="图片广告" :value="1"></el-option>
                    <el-option label="软文广告" :value="2"></el-option>
                    <el-option label="视频广告" :value="3"></el-option>
                </el-select>
            </el-form-item>
            <!-- <el-form-item label="广告状态：">
        <el-select v-model="searchForm.status" size="small">
          <el-option label="全部" :value="0"></el-option>
          <el-option label="待支付" :value="-1"></el-option>
          <el-option label="审核中" :value="1"></el-option>
          <el-option label="已通过" :value="2"></el-option>
          <el-option label="已驳回" :value="3"></el-option>
          <el-option label="已取消" :value="4"></el-option>
        </el-select>
      </el-form-item> -->
            <el-form-item label="广告标题：">
                <el-input v-model="searchForm.title" size="small" placeholder="请输入广告标题"></el-input>
            </el-form-item>
            <el-form-item label=" " label-width="20px">
                <el-button size="small" @click="search" type="primary">搜索</el-button>
                <el-button size="small" @click="searchClear" type="text">清除搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-tabs type="card" v-model="searchForm.status" @tab-click="tabClick">
            <el-tab-pane :name="item.status" v-for="(item, index) in orderStatus" :key="index">
                <span slot="label">{{ item.title }}（{{ item.count }}）</span>
            </el-tab-pane>
        </el-tabs>
        <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="order_no" label="订单编号" align="center"></el-table-column>
            <!-- <el-table-column prop="avatar" label="用户信息" align="center" width="200">
        <template v-slot="{ row }">
          <userInfo :name="row.nickname" :avatar="row.avatar"></userInfo>
        </template>
      </el-table-column> -->
            <el-table-column prop="mobile" label="联系电话" align="center"></el-table-column>
            <el-table-column prop="type" label="广告类型" align="center">
                <template v-slot="{ row }">{{ row.type == 1 ? '图片广告' : row.type == 2 ? '软文广告' : '视频广告' }}</template>
            </el-table-column>
            <el-table-column prop="title" label="广告标题" align="center"></el-table-column>
            <el-table-column prop="day" label="申请天数" align="center"></el-table-column>
            <el-table-column prop="total_price" label="广告价格" align="center">
                <template v-slot="{ row }">￥{{ row.total_price }}</template>
            </el-table-column>
            <el-table-column prop="discount" label="会员折扣" align="center">
                <template v-slot="{ row }">￥{{ row.discount || '--' }}</template>
            </el-table-column>
            <el-table-column prop="discount_price" label="折后价格" align="center">
                <template v-slot="{ row }">￥{{ row.discount_price }}</template>
            </el-table-column>
            <el-table-column prop="amount" label="支付金额" align="center">
                <template v-slot="{ row }">￥{{ row.amount }}</template>
            </el-table-column>
            <el-table-column prop="pay_integral" label="支付积分" align="center"></el-table-column>
            <el-table-column prop="create_time" label="创建时间" align="center">
                <template v-slot="{ row }">{{ row.create_time | getDateformat }}</template>
            </el-table-column>
            <el-table-column prop="status" label="广告状态" align="center">
                <template v-slot="{ row }">
                    <span
                        :style="{ color: row.status == -1 || row.status == 2 ? '#409EFF' : row.status == 1 ? '#e6a23c' : '#f56c6c' }">
                        {{ row.status == 1 ? '审核中' : row.status == 2 ? '已通过' : row.status == 3 ? '已驳回' : row.status == 4 ?
                            '已取消' : '待支付' }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="money" label="操作" width="200" align="center" fixed="right">
                <template v-slot="{ row }">
                    <el-button type="text" @click="seeApply(row.id)">查看申请</el-button>
                    <el-button type="text" v-if="row.status == 1" @click="audit(row)">审核</el-button>
                    <el-button type="text" v-if="row.status == 3" @click="seeRemark(row.id)">驳回原因</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
        </Paging>
        <el-dialog title="查看申请" :visible.sync="showApplyInfo" width="700px">
            <el-form class="applyInfo" label-width="90px">
                <el-form-item label="广告标题：">{{ orderInfo.title }}</el-form-item>
                <el-form-item label="广告类型：">{{ orderInfo.type == 1 ? '图片广告' : orderInfo.type == 2 ? '软文广告' : '视频广告'
                }}</el-form-item>
                <el-form-item :label="orderInfo.type == 1 ? '广告图片：' : orderInfo.type == 2 ? '软文封面：' : '视频封面：'">
                    <el-image style="width: 160px; height: 100px" :src="orderInfo.picture"
                        :preview-src-list="[orderInfo.picture]"></el-image>
                </el-form-item>
                <el-form-item label="视频内容：" v-if="orderInfo.type == 3">
                    <video :src="orderInfo.video"></video>
                </el-form-item>
                <el-form-item label="展示位置：" v-if="orderInfo.type == 1">{{ orderInfo.show_location ? '商品分类banner' :
                    '首页banner' }}</el-form-item>
                <el-form-item label="申请天数">{{ orderInfo.day }}</el-form-item>
                <!-- <el-form-item label="跳转路径">{{orderInfo.day}}</el-form-item> -->
                <el-form-item label="广告价格：">{{ orderInfo.total_price }}</el-form-item>
                <el-form-item label="支付金额：">{{ orderInfo.amount }}</el-form-item>
                <el-form-item label="支付积分：">{{ orderInfo.pay_integral }}</el-form-item>
                <el-form-item label="软文内容：" v-if="orderInfo.type == 2">
                    <div v-html="orderInfo.content"></div>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="驳回原因" :visible.sync="showRemark" width="700px">
            {{ orderInfo.remark }}
        </el-dialog>
        <el-dialog title="审核" :visible.sync="showAudit" width="650px">
            <el-form ref="form" label-width="100px">
                <el-form-item label="审核结果：">
                    <el-radio-group v-model="auditForm.status">
                        <el-radio :label="2">通过</el-radio>
                        <el-radio :label="3">驳回</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="上架时间：" v-if="auditForm.status == 2">
                    <el-date-picker v-model="auditForm.show_time" @change="handle" type="datetime" :picker-options="option"
                        placeholder="选择日期时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="下架时间：" v-if="auditForm.status == 2">{{ getDateformat(auditForm.end_time)
                }}</el-form-item>
                <el-form-item label="驳回原因：" v-if="auditForm.status == 3">
                    <el-input v-model="auditForm.remark" type="textarea" :rows="5"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showAudit = !1">取 消</el-button>
                <el-button type="primary" @click="confirmAudit">确 定</el-button>
            </span>
        </el-dialog>
    </el-main>
</template>

<script>
// import userInfo from '@/components/userInfo';
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import _ from 'lodash';
export default {
    components: {
        // userInfo,
        Paging,
    },
    data () {
        return {
            searchForm: {
                page: 1,
                rows: 10,
                order_no: '',
                nickname: '',
                title: '',
                type: 0,
                start_time: '',
                status: 0,
            },
            list: [],
            orderInfo: {},
            orderStatus: [],
            total_number: 0,
            showApplyInfo: !1,
            showRemark: !1,
            showAudit: !1,
            types: '',
            show_location: '',
            city_id: '',
            show_time: 0,
            auditForm: {
                id: '',
                status: 2,
                show_time: '',
                remark: '',
                end_time: '',
            },
            option: {},
        };
    },
    filters: {
        getDateformat: getDateformat,
    },
    created () {
        this.getOrderStatus();
        this.getList();
    },
    methods: {
        getDateformat (val) {
            return getDateformat(val);
        },
        tabClick () {
            this.searchForm.page = 1;
            this.getList();
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
            } else {
                this.searchForm.page = val;
            }
            this.getList();
        },
        search () {
            this.searchForm.page = 1;
            this.getOrderStatus();
            this.getList();
        },
        searchClear () {
            this.searchForm = {
                page: 1,
                rows: 10,
                order_no: '',
                nickname: '',
                title: '',
                type: 0,
                start_time: '',
                status: 0,
            };
            this.getList();
        },
        handle () {
            if (this.auditForm.show_time < this.show_time) {
                this.auditForm.show_time = this.show_time;
            }
            this.auditForm.end_time = this.auditForm.show_time / 1000 + this.orderInfo.day * 86400;
        },
        audit (row) {
            let that = this;
            this.auditForm = {
                id: '',
                status: 2,
                show_time: '',
                remark: '',
                end_time: '',
            };
            this.$axios.post(this.$api.gateway.Ad.orderInfo, { id: row.id }).then(res => {
                if (res.code == 0) {
                    let info = res.result;
                    this.orderInfo = info;
                    this.$axios
                        .post(this.$api.gateway.Ad.showTime, {
                            types: info.type,
                            show_location: info.show_location,
                            city_id: info.city_id,
                        })
                        .then(res => {
                            if (res.code == 0) {
                                this.showAudit = !0;
                                this.auditForm.show_time = res.result.show_time * 1000;
                                this.show_time = this.auditForm.show_time;
                                this.auditForm.end_time = this.auditForm.show_time / 1000 + this.orderInfo.day * 86400;
                                this.option = {
                                    disabledDate: time => {
                                        return time.getTime() < that.show_time - 1 * 24 * 3600 * 1000;
                                    },
                                };
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        confirmAudit () {
            let data = {
                id: this.orderInfo.id,
                status: this.auditForm.status,
            };
            if (data.status == 2) {
                if (!this.auditForm.show_time) return this.$message.warning('请选择上架时间');
                data.show_time = this.auditForm.show_time / 1000;
            }
            if (data.status == 3) {
                if (!this.auditForm.remark) return this.$message.warning('请填写驳回原因');
                data.remark = this.auditForm.remark;
            }
            this.$axios.post(this.$api.gateway.Ad.orderAudit, data).then(res => {
                if (res.code == 0) {
                    this.$message.success("处理成功");
                    this.showAudit = !1;
                    this.getList();
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getOrderStatus () {
            let searchForm = _.cloneDeep(this.searchForm);
            if (searchForm.start_time) {
                searchForm.start_time = searchForm.start_time[1] / 1000;
                searchForm.end_time = searchForm.start_time[0] / 1000;
            }
            this.$axios.post(this.$api.gateway.Ad.orderStatus, searchForm).then(res => {
                if (res.code == 0) {
                    this.orderStatus = res.result;
                }
            });
        },
        getList () {
            let searchForm = _.cloneDeep(this.searchForm);
            if (searchForm.start_time) {
                searchForm.start_time = searchForm.start_time[1] / 1000;
                searchForm.end_time = searchForm.start_time[0] / 1000;
            }
            this.$axios.post(this.$api.gateway.Ad.order, searchForm).then(res => {
                if (res.code == 0) {
                    this.list = res.result.list;
                    this.total_number = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        seeApply (id) {
            this.$axios.post(this.$api.gateway.Ad.orderInfo, { id }).then(res => {
                if (res.code == 0) {
                    this.showApplyInfo = !0;
                    this.orderInfo = res.result;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        seeRemark (id) {
            this.$axios.post(this.$api.gateway.Ad.orderInfo, { id }).then(res => {
                if (res.code == 0) {
                    this.showRemark = !0;
                    this.orderInfo = res.result;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
    },
};
</script>
  
<style lang="less" scoped>
.el-main {
    background: #fff;

    .applyInfo {
        .el-form-item {
            margin-bottom: 0px;
        }
    }
}
</style> 